import React, {useEffect} from 'react'
import './whoWeAre.css';
import Footer from '../footer/Footer';

import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Card from 'react-bootstrap/Card'; //

function WhoWeAre({wwa1,wwa2,wwa3,indiaMap,indiamapimg,Logo,dhiraj,bala,pankaj}) {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);


  const images = [
    {
      frontImage: dhiraj,
      backImage: 'path-to-back-image-1',
      Fname: 'Dheeraj Rathi',   
      dsc:" \'Dheeraj excels in diverse industry business development and specializes in Finance and Governance consulting. With 22+ years' global experience and a board seat in 15+ companies, he champions alignment for strategic solutions, blending people, technology, and expertise.\'"
    },
    {
      frontImage: bala,
      backImage: 'path-to-back-image-2',
      Fname: 'D. Bala',
      dsc:"\'D. Bala brings to the table over two decades of diverse financial expertise from institutions like Crisil, SBICAPs, KPMG, Brescon, and Adventity. As a seasoned consulting leader, he has catered to notable clients such as Netrika Consulting, Wealth First, PASIA, Ecovis, BM Trada Certifications, Adfactors PR, and Sixth Sense Analytics. His specialties span across areas like Forensics, Risk Advisory, Strategic Financial Planning, and Business Development.\'"
    },
    {
      frontImage: pankaj,
      backImage: 'path-to-back-image-3',
      Fname: 'Pankaj Bhargav',
      dsc:"\'Pankaj spearheads diverse industry consulting and innovative offerings, emphasizing collective wisdom and facilitative leadership for sustainable change. With 30+ years in HR and Organizational Development, he's partnered with 100+ organizations, driving growth through collaborative planning and facilitation\'"
    },
  ];

  return (
    <div className='container-fluid'>
       <div className='content-div '>
        <div className='row'> 
        <div className='col-md-2'></div>
        <div className='col-md-8'>
              <h1 className='wwa-heading text-center'>Who are we?</h1>
            <div className='w-100 text-center'><span className='wwa-desc'>We are a team of experienced entrepreneurs and industry experts works closely with each startup to provide strategic guidance, business development support, and access to investors and customers</span></div>
         
        </div>
        <div className='col-md-2'></div>
        </div>
     
       </div>
         <div className='d-flex justify-content-around animate__fadeInDown' style={{zIndex:'4',position:'relative'}}>
         <div  className='chunks-container-1'>
             <div className='wwa-chunks-div' >
            <img className='wwa-chunks' src={wwa1} ></img>
            </div> 
             <p className='chunk-names' style={{}}>Multidisciplinary <br></br> Consultants </p>
           </div>
            <div  className='chunks-container-2'>
            <div className='wwa-chunks-div'>
            <img className='wwa-chunks' src={wwa2} ></img>
            </div> 
            <span className='chunk-names'>Global Connects</span>  
            </div>
            <div  className='chunks-container-3'>
             <div className='wwa-chunks-div' >
            <img className='wwa-chunks' src={wwa3} ></img>
            </div> 
             <span className='chunk-names'>Tech Catalysts</span>
           </div>
           
      </div>
      <div className='col-md-12 india-map-div'>
        <img src={indiamapimg} alt='indiamap' title='map' className='india-map-img'></img>
      </div>
      <div className="card-container  px-3">
      <div className="row">
        {images.map((image, index) => (
          <div className="col-md-4 d-flex justify-content-center" key={index}>
          <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img class="image" src={image.frontImage} alt="Front Image" />
                  <p class="name">{image.Fname}</p>
                </div>
                <div class="flip-card-back px-2 py-2" style={{fontSize:'12px' ,fontStyle:'oblique'}}>
                  <p class="">{image.dsc}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>

      <Footer  Logo={Logo} />
    </div>
  )
}

export default WhoWeAre