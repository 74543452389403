import './App.css';
import Header from './components/header/Header';

import {
  BrowserRouter as Router,useLocation ,
  Route,Routes
} from 'react-router-dom';


import "bootstrap/dist/css/bootstrap.min.css";
import logo from './newLogo.png';
import LandingPage from './components/landingPage/LandingPage';
import landingImage from './images/landing-image.jpg';
import computerImg from './images/computer-image.jpg'
import HowWeEngage from './components/howWeEngage/HowWeEngage';
import BuildModel from './components/buildModel/BuildModel';
import BMimg from './images/buildModel.jpg';
import buddhaWhite from './images/buddha-white.png';
import blackBuddhaImg from './images/Web-1920 .jpg'
import WhoWeAre from './components/whoWeAre/WhoWeAre';
import wwa1 from './images/wwa-1.jpg';
import wwa2 from './images/wwa-2.jpg';
import wwa3 from './images/wwa-3.jpg';
import indiaMap from './images/india-map.png';
import Logo from './images/logo.png';

// carausel images
import ccl from './images/partnerLogo/Core-Creator-Logo.png';
import gbtl from './images/partnerLogo/GBT-Logo.png';
import healthstart from './images/partnerLogo/healthstart.png';
import hungryHead from './images/partnerLogo/hungry-head.png';
import mentorlogo from './images/partnerLogo/mentor-new-logo.png'
import indiamapimg from './images/Margom-website-edits.png';
import FinalLogo from './images/MARGOM-LOGO-FINAL.png'
import waterDrop from './images/water-drop.png';
import editedImg from './images/who-are-we.png';
import drop_1 from './images/drop-1.png'
import drop_2 from './images/drop-2.png'
import drop_3 from './images/drop-3.png'
import drop_4 from './images/drop-4.png';
import wd1 from './images/wd-1.png'
import wd2 from './images/wd-2.jpg'
import wd3 from './images/wd-3.png'
import wd4 from './images/wd-4.png'
import iitalimini from './images/iitalimini.png';
import ecovis from './images/2.png';
import corecrea from './images/3.png';
import gbtech from './images/4.png';
import pcg from './images/pcg.png';
import rkca from './images/rkca.png';
// patrons images

import deepa from './images/patrons-images/deepa-rathi.png';
import sanjeev from './images/patrons-images/sanjeev-bindal.png';
import dhiraj from './images/patrons-images/dheeraj-rathi.png';
import pankaj from './images/patrons-images/pankaj.png';
import landingimg from './images/seedling-plant-are-growing-money-coin-tower-with-blurred-bokeh-background-business-finance-concept.jpg'
import Footer from './components/footer/Footer';
import our_patron from './images/our-patrons.jpg';
import bala from './images/10_Bala.png'
import Ecosystem from './components/ecosystem/ecosystem';
// import bala from './images/10_Bala.png'
function App() {
  return (
    <Router>
    <div className="App ">
      <Header  Logo={FinalLogo}  />
      <Routes>
       <Route exact path='/' element={<LandingPage landingImage={landingImage} buddhaWhite={buddhaWhite} 
        landingimg={landingimg}
       ccl={ccl} gbtl={gbtl} healthstart={healthstart} hungryHead={hungryHead} mentorlogo={mentorlogo} 
       deepa={deepa} sanjeev={sanjeev} dhiraj={dhiraj} pankaj={pankaj}  Logo={FinalLogo} our_patron={our_patron}
       /> }></Route>


       <Route exact path='/how-we-engage' element={<HowWeEngage computerImg={computerImg} blackBuddhaImg={blackBuddhaImg} waterDrop={waterDrop} drop_1={drop_1} drop_2={drop_2} drop_3={drop_3} drop_4={drop_4} Logo={FinalLogo} editedImg={editedImg} wd1={wd1} wd2={wd2} wd3={wd3} wd4={wd4} />}></Route> 



       <Route exact path='/model' element={<BuildModel BMimg={BMimg} buddhaWhite={buddhaWhite} editedImg={editedImg} Logo={FinalLogo} />}></Route> 


       <Route exact path='/who-we-are' element={<WhoWeAre wwa1={wwa1} wwa2={wwa2} wwa3={wwa3} indiaMap={indiaMap} indiamapimg={indiamapimg}  Logo={FinalLogo} dhiraj={dhiraj} bala={bala} pankaj={pankaj}  />} ></Route>
       <Route exact path='/ecosystem' element={<Ecosystem iitalimini={iitalimini} ecovis={ecovis} corecrea={corecrea} gbtech={gbtech} pcg={pcg} rkca={rkca} Logo={FinalLogo} />} ></Route>

       
      </Routes>
      {/* <Footer  Logo={Logo} /> */}
    </div>
    </Router>
  );
}

export default App;
