import React, { useEffect } from 'react';
import './header.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate,useLocation  } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import $ from 'jquery';

function Header(props) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentUrl = window.location.pathname;
    console.log('currentUrl', currentUrl);

    // Remove border from all links first
    $('#header-links-container a p').css('border-bottom', 'none');

    // Add border to the active link
    $('#header-links-container a').each(function() {
      const link = $(this);
      console.log(link.attr('href') === currentUrl);

      if (link.attr('href') === currentUrl) {
        link.find('p').css('border-bottom', '3px solid #1B6166');
      }
    });
  }, [location]); // Run this effect on every location change
  return (
    <div className='container-fluid header-bg' >
      <div className='d-flex add-padding'>
        {/* <div className='col-md-1'></div> */}
        <div className='d-flex col-md-11 justify-content-between header-main-div'>
          <div className='col-md-3 logo-div' onClick={() => navigate('/')}>
            
            <img onClick={() => navigate('/?type=1')} src={props.Logo} alt='logo' width='200px' className='logo'></img>
          </div>
          <div className='col-md-4'></div>
          
           <div className='col-md-4 '>
           <div className=' justify-content-end get-in-touch-div '>
          <div className='col-md-11 d-flex justify-content-end'>
            <button className='btn btn-success get-in-touch mt-4' onClick={()=>navigate('/model?type=2')}>Get In Touch</button>
            </div>
          {/* <div className='col-md-1'> </div> */}
          
            </div>
            <div className='justify-content-between align-items-end desk-header' id='header-links-container' >
            <a href='/' className='header-links cool-link'><p className='p-header'>Home</p></a>

            <a className='header-links cool-link' href='/who-we-are'> <p className='p-header'>About Us</p></a>

            <a  href='/ecosystem' className='header-links cool-link'><p className='p-header'> Our Ecosystem</p></a>
            <a href='/how-we-engage' className='header-links cool-link' style={{ textAlign: '-webkit-center' }}><p className='p-header'>Models</p> </a>
          
            </div>
           
          </div>

          <div className='w-100  justify-content-end mobile-header'>
              <NavDropdown title={<MenuIcon></MenuIcon>} id="basic-nav-dropdown" >
              <NavDropdown.Item onClick={()=>navigate('/')}>Home</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>navigate('/who-we-are')}>About Us</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>navigate('/?type=2')}>Our Patrons</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>navigate('/how-we-engage')}>Models</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>navigate('/model?type=2')}><button className='btn btn-success get-in-touch my-2'>Get In Touch</button></NavDropdown.Item>
              </NavDropdown>
          </div>
        </div>
        <div className='col-md-2'></div>
      </div>
    </div>
  )
}

export default Header