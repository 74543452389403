import React, { useEffect, useState } from 'react'
import './buildModel.css';
import buildModel from '../../images/buildModel.jpg';
import mandateModel from '../../images/mandate.jpg';
import contact from '../../images/contact.png';
import coPromoter from '../../images/co-promoter.jpg';
import co_founder from '../../images/co-promoter.jpg';
import growth from '../../images/growth.png';
import entartica from '../../images/entartica-logo.png';
import expovan from '../../images/expovan.png';
import mentorbi from '../../images/MENTOR-logo.png';
import medimine from '../../images/partnerLogo/healthstart.png';
import Footer from '../footer/Footer';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';

function BuildModel(props) {

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  const Models=[
    {
      model:'build_Model',
      heading:'Build Model',
      description:'Build model, is ideal for entrepreneurs who are seeking capacity and capital. Through this module, we provide startups with access to funding, as well as resources and support to help them build and scale their operations',
      img:buildModel,
      companyLogo:entartica,
      tagline:" \'Leading India's water recreation. Build Model empowers startups with capital and resources for scaling, shaping a global aquatic leisure chain.\'"
    },
    {
      model:'Mandate_Model',
      heading:'Mandate Model',
      description:'The mandate model is ideal for businesses that are seeking clarity and capacity. Through this module, we provide startups with access to experienced consultants and advisors who can provide strategic guidance and help them build the capacity they need to scale their businesses',
      img:mandateModel,
      companyLogo:expovan,
      tagline:"\'Elevating Vanilla processing. Mandate Model empowers startups with expert guidance and capacity-building, fostering growth in the Industry.\'"
    },
    {
      model:'promoter_Model',
      heading:'Promoter Model',
      description:'The promoter model is ideal for businesses who are seeking connection and capital. Through this model, we connect businesses, who are engaged with us through GB Tech with investors and other industry players who can provide funding, networking opportunities, and other resources and help them scale towards growth model.',
      img:coPromoter,
      companyLogo:mentorbi,
      tagline:"\'Fueled by consulting prowess. Co-promoter model links startups with investors and industry players, fostering capital and connections for impactful growth.\'"
      
    },
    {
      model:'Co-founder_Model',
      heading:'Co-founder Model',
      description:'Co-founder model, which is ideal for entrepreneurs who are seeking clarity and connection. Through this module, we connect entrepreneurs with experienced co-founders who can provide guidance, mentorship, and support as they build their businesses.',
      img:co_founder,
      companyLogo:medimine,
      tagline:"\' Spearheading clinic digitisation. Co-founder model fosters clarity, connecting visionaries with experienced mentors for healthcare evolution\'"


    },
    {
      model:'growth_Model',
      heading:'Growth Model',
      description:'The growth model is ideal for businesses who are seeking capacity and capital. Through this model, we provide businesses with access to funding, as well as resources and support to help them build and scale their operations',
      img:growth,
      companyLogo:medimine,
      tagline:"\' Spearheading clinic digitisation. Co-founder model fosters clarity, connecting visionaries with experienced mentors for healthcare evolution\'"


    }
  ];
  const contactUs=[
    {
      model:'Contact_Us',
      heading:'Contact Us',
      description:'If you believe you can create magic and we can help you with our connections, capability, clarity, capital, let’s start our story with few words to us.',
      email:'margom@official.com',
      img:contact

    },
  ];
const [mapArray,setMapArray]=useState([])

let link =window.location.href;
let type =link.split('?type=').pop()


useEffect(()=>{
  switch(type){
    case '1': 
    setMapArray(Models)
    break;
    case '2': setMapArray(contactUs[0])
    break;
    case '3': setMapArray(Models[0])
    break;
    case '4': setMapArray(Models[1])
    break;
    case '5': setMapArray(Models[2])
    break;
    case '6': setMapArray(Models[4])
    break;
  }
},[]);
console.log(mapArray);
const initialFormData = {
  name: '',
  email: '',
  number: '',
  attachment: null
};

const [formData, setFormData] = useState(initialFormData);

const [formErrors, setFormErrors] = useState({
  name: '',
  email: '',
  number: '',
  attachment: ''
});

const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevFormData) => ({
    ...prevFormData,
    [name]: value
  }));
};

const handleAttachmentChange = (e) => {
  const file = e.target.files[0];
  setFormData((prevFormData) => ({
    ...prevFormData,
    attachment: file
  }));
};

const handleSubmit = (e) => {
  e.preventDefault();

  // Perform validation
  const errors = validateForm();
  setFormErrors(errors);

  if (Object.keys(errors).length === 0) {
    // No validation errors, submit the form
    console.log(formData);
    resetForm()
  }
};

const validateForm = () => {
  const errors = {};

  if (!formData.name) {
    errors.name = 'Name is required *';
  }

  if (!formData.email) {
    errors.email = 'Email is required *';
  } else {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      errors.email = 'Invalid email format *';
    }
  }

  if (!formData.number) {
    errors.number = 'Number is required *';
  } else if (formData.number.length !== 10) {
    errors.number = 'Number must be 10 digits *';
  }

  return errors;
};
const resetForm = () => {
  setFormData(initialFormData);
  setFormErrors({});
};
  return (
    <div className='container-fluid'>
      <div style={{height:'10vh',backgroundColor:'#DBEFE1'}} className='extraSpace'></div>
      {
        type != '1' ? 
        <div>
        <div className={`row ${type=='3' ? 'bg-contact' : null} `}>
            <div className={`col-md-6 ${type=='3' ? 'bg-contact' :'bg-bm-1'} `}>
                <img src={mapArray.img} width='450px' style={{padding:' 20px'}} className='bm-img'></img>
            </div>
            <div className='col-md-6 d-flex bg-bm-2'>
                <div className='col-md-1'></div>
            <div className="col-md-10 align-self-center">
              <div className='text-start build-model-text'>
                <h2>{mapArray.heading}</h2>
                <span className='bm-desc'>{mapArray.description}</span>
                 {/* <div className='mt-5 d-flex align-items-center'> <div className='mx-2'><img src={mapArray.companyLogo} width="100px"></img></div>  <div><span className='fst-italic' style={{fontSize:'15px'}}>{mapArray.tagline}</span></div>  </div> */}
                { type == '2' && <p className='my-5 bm-desc'>Email : {mapArray.email} </p> }   
              </div>
              </div>

              <div className='col-md-1'></div>
            </div>
        </div>
        </div>
        : mapArray.map((model)=>{
          return (

          
          <div>
          <div className={`row ${type=='3' ? 'bg-contact' : null} `}>
              <div className={`col-md-6 ${type=='3' ? 'bg-contact' :'bg-bm-1'} `}>
                  <img src={model.img} width='450px' style={{padding:' 20px'}} className='bm-img'></img>
              </div>
              <div className='col-md-6 d-flex bg-bm-2'>
                  <div className='col-md-1'></div>
              <div className="col-md-10 align-self-center">
                <div className='text-start build-model-text'>
                  <h2>{model.heading}</h2>
                  <span className='bm-desc'>{model.description}</span>  
                </div>
                </div>
                <div className='col-md-1'></div>
              </div>
          </div>
          <div className='bg-bottom-city-img'>
              <img src={props.editedImg} className='bm-bottom-img'></img>
          </div> </div>)
        })
      }
    <div className='bg-bottom-city-img'>
              <img src={props.editedImg} className='bm-bottom-img'></img>
          </div>
  
        <Footer  Logo={props.Logo} />
    </div>
  )
}

export default BuildModel;