import React, { useState,useEffect } from 'react'
import './landing.css';
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import Footer from '../footer/Footer';
import { useParams } from 'react-router-dom';
import rlkabra from '../../images/rlkabra.jpg';
import arvind from '../../images/patrons-images/arvind-poddar.webp';
import anand from '../../images/patrons-images/anad-rathi.webp';
import rampal from '../../images/patrons-images/rampal.jpg';

function LandingPage({landingImage,buddhaWhite, landingimg,ccl,gbtl,healthstart,hungryHead,mentorlogo,deepa,sanjeev,dhiraj,pankaj,Logo,our_patron}) {
  const { variable1 } = useParams();
  const navigate=useNavigate();
  let link = window.location.href;
  
  let type =link.split('?type=').pop();
  let flag = '1';
  if(type =='2'){
    flag= '2';
  }

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  
  const onSelectModel=(event)=>{
  switch(event.target.value){
    // case 'build-model' : navigate('model?type=3');
    // break;
    case 'mandate-model' : navigate('model?type=4');
    break;
    case 'promoter-model' : navigate('model?type=5');
    break;
    case 'growth-model' : navigate('model?type=6');
    break;
  }
  }
 
  const ourEngagements=(event)=>{
    switch(event.target.value){
      case 'ECHT' : navigate('model?type=3');
      break;
      case 'Expovan' : navigate('model?type=4');
      break;
      case 'Mentor' : navigate('model?type=5');
      break;
      case 'Medimine' : navigate('model?type=6');
      break;
    }
    }
  return (
    <div className='container-fluid' style={{padding:'0px'}}>
      <div className='d-flex landing-page ' >
        <div className='col-md-1 col-sm-2'></div>
        <div className=' col-md-3 col-sm-4 text-start landing-heading-div' >
        <div className='fade-in-text'>
         <h3 className='landing-heading'>{flag !== '2' ? 'Nurturing startups from idea to scale':'Trust is the currency we know as wealth'}</h3>
         <button className='btn btn-success get-in-touch my-2' onClick={()=>navigate('/how-we-engage')}>Explore More</button>
        </div></div>
        <div className='col-md-2 col-sm-1'></div>
        <div className='col-md-6 col-sm-6'>
          <img src={flag != '2' ? landingimg : our_patron} alt='landingImage'  className='landingImage'></img>
        </div>
      </div>
     <div  className={`d-flex bg-select`} >
     <div className='col-md-2'></div>
     { flag != '2' ?
     <div className='col-md-3 col-sm-12 d-flex justify-content-center'>
     <div className='w-100'>
     <select className="form-select select-design w-100" aria-label="Default select example" placeholder='Select Business Stage' onChange={ourEngagements}>
        <option defaultValue hidden>Select Business Stage</option>
        <option className="options" value="ECHT" style={{paddingBottom:'20px'}}>ECHT</option>
        <option className="options" value="Expovan">Expovan</option>
        <option className="options" value="Mentor">Mentor</option>
        <option className="options" value="Medimine">Medimine</option>
        {/* <option className="options" value="Protect">Protect</option>
        <option className="options" value="Exit">Exit</option> */}
        </select>
     </div>
     </div>
      : null
      }
      
     <div className='col-md-2'></div>
     { flag != '2' ?
     <div className='col-md-3 col-sm-12 d-flex justify-content-center'>
        <div className='w-100'>
        <select className="form-select select-design w-100" aria-label="Default select example" onChange={onSelectModel}>
        <option defaultValue hidden>Our Offerings</option>
        {/* <option className="options" value="build-model">Build Model</option> */}
        <option className="options" value="promoter-model">Promoter Model</option>
        <option className="options" value="growth-model">Growth Model</option>
         <option className="options" value="mandate-model">Mandate Model</option>
        </select>
     </div>
     </div>
       : null
      }
     <div className='col-md-2'></div>
     </div>

     <div>
      {
        flag != '2' ?
     <Carousel fade>
      <Carousel.Item className='d-flex justify-content-evenly caraousel'>
            <div >
                <img src={ccl} alt='logos' width='200px' className='slider'></img>
            </div>
            <div className='tagline'>
          <p style={{fontFamily:'cursive'}} className='slider'>"Strategy and Performance Consulting Platform"</p></div>
      </Carousel.Item>
      <Carousel.Item className='d-flex justify-content-evenly caraousel'>
      <div >
        <img src={gbtl} alt='logos' width='200px' className='slider'></img>
      </div>
      <div className='tagline'>
      <p style={{fontFamily:'cursive'}} className='slider'>"Technology and beyond. An AI and Data Science Company"</p>
      </div>
      </Carousel.Item>
      <Carousel.Item className='d-flex justify-content-evenly caraousel'>
      <div >
        <img src={hungryHead} alt='logos'  width='100px' height='100px' className='slider medimine'></img>
      </div>
      <div className='tagline'>
        <p style={{fontFamily:'cursive'}} className='slider'>"Revolution Foods Pvt Ltd : Cloud Kitchen Enabled fast food QSR Chain"</p>
     </div>
      </Carousel.Item>
      <Carousel.Item className='d-flex justify-content-evenly caraousel'>
      <div >
        <img src={healthstart} alt='logos' width='100px' height='100px' className='slider medimine'></img>
      </div>
      <div className='tagline'>
        <p style={{fontFamily:'cursive'}} className='slider'>"Leveraging technology : Enabling health Care :  Application to enable Digital transformation of Health Care Clinics </p>
        </div>
      </Carousel.Item>
      <Carousel.Item className='d-flex justify-content-evenly caraousel'>
      <div >
        <img src={mentorlogo} alt='logos' width='200px' height='70px' className='slider paramount'></img>
      </div>
      <div className='tagline'>
        <p style={{fontFamily:'cursive'}} className='slider'>"Mentor Bi : Multiplying Entity Networth : Business Enabling Technology Accelerator for SME "</p> </div>
      </Carousel.Item>
    </Carousel>
    :
    <Carousel fade>
      <Carousel.Item className='d-flex justify-content-evenly caraousel'>
            <div className='patron-img-div'>
                <img src={arvind} alt='logos' width='160px' className='slider rounded-circle'></img>
            </div>
            <div className='tagline'>
          <p style={{fontFamily:'cursive'}} className='slider'>"It's not about ideas. It's about making ideas happen - Arvind Poddar"</p></div>
      </Carousel.Item>
      <Carousel.Item className='d-flex justify-content-evenly caraousel'>
      <div className='patron-img-div'>
        <img src={anand} alt='logos' width='160px' className='slider rounded-circle'></img>
      </div>
      <div className='tagline'>
      <p style={{fontFamily:'cursive'}} className='slider'>"The best way to predict the future is to create it. - Anand Rathi"</p>
      </div>
      </Carousel.Item>
      <Carousel.Item className='d-flex justify-content-evenly caraousel'>
      <div className='patron-img-div'>
        <img src={rlkabra} alt='logos'  width='160px' className='slider rounded-circle'></img>
      </div>
      <div className='tagline'>
        <p style={{fontFamily:'cursive'}} className='slider'>"In business, the hunger for knowledge fuels growth and success."</p>
     </div>
      </Carousel.Item>
      <Carousel.Item className='d-flex justify-content-evenly caraousel'>
      <div className='patron-img-div'>
        <img src={rampal} alt='logos'  width='160px' className='slider rounded-circle'></img>
      </div>
      <div className='tagline'>
        <p style={{fontFamily:'cursive'}} className='slider'>"The biggest risk is not taking any risk. In a world that is changing quickly, the only strategy that is guaranteed to fail is not taking risks. - Ram Pal Soni"</p>
     </div>
      </Carousel.Item>
    </Carousel>
      }
     </div>
     <Footer  Logo={Logo} />
    </div>
  )
}

export default LandingPage