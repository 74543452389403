import './ecosystem.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../footer/Footer';
export default function Ecosystem({iitalimini,ecovis,corecrea,gbtech,rkca,pcg,Logo}){
  
    return (
        <section className="ecosystem-section ">
      <div className="">
        <h1 className="mb-4 eco-heading text-center pt-4" style={{fontSize:'3.5rem'}} >Our Ecosystem</h1>
        <div className="row pt-4">
          <div className="col-md-7 d-flex align-items-center ">
            <div className="pivoted-by  col-md-10">
              <h3>Pivoted By</h3>
              <p>Write up on IIT Alumni Council</p>
              <p className=''style={{textAlign:'justify'}} >
              The IIT Alumni Council is an autonomous non-profit organization. The Supreme Council is made up of representatives from several batches and municipal governments. The 11 Alumni Council now has over 45,000 registered volunteer members and 500+ council members. The Council is unaffiliated with the IlTs and any Campus Specific Alumni Associations.
              </p>
            </div>
          
          </div>
          <div className="col-md-5">
            <img src={iitalimini} alt="Logo 2" className="img-fluid mt-3" width={'80%'} />
          </div>
        </div>
        <div className="row mb-5">
        <div className="col-md-5 d-flex justify-content-end align-items-center">
            <img src={ecovis} alt="Logo 2" className="img-fluid mt-3" width={'75%'} />
          </div>
          <div className="col-md-7 d-flex justify-content-end align-items-center ">
            <div className="exp-by  col-md-10 d-flex align-items-center">
                <div>
              <h3>Our Experience</h3>
             
              <p className=''style={{textAlign:'justify'}} >
              Embark on a 50-year journey with us across the industry, where we've excelled in Law, Accounting, Business enablement, and Tech Acceleration. Explore our wealth of experience and expertise in these essential domains
              </p>
              </div>
            </div>
          
          </div>
         
        </div>
        <div className=" bg-white mt-5" style={{    padding: '3% 0px'}}>
        <h1 className="my-4 eco-heading text-center">Our Associates</h1>
        <div className="d-flex justify-content-center  mt-4">
            
          <div className="col-3 col-sm-2">
            <img src={corecrea} alt="Associate Logo 1" className="img-fluid" />
          </div>
          <div className="col-3 col-sm-2">
            <img src={pcg} alt="Associate Logo 2" className="img-fluid" />
          </div>
          <div className="col-3 col-sm-2">
            <img src={gbtech} alt="Associate Logo 3" className="img-fluid" />
          </div>
          <div className="col-3 col-sm-2">
            <img src={rkca} alt="Associate Logo 5" className="img-fluid" />
          </div>
        </div>
        </div>
       
      </div>
      <Footer  Logo={Logo} />
    </section>
      
    )
}