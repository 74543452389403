import React,{useEffect} from 'react'
import './howWeEngage.css';
import Footer from '../footer/Footer';
import buildModel from '../../images/buildModel.jpg';
import mandateModel from '../../images/mandate.jpg';
import contact from '../../images/contact.png';
import coPromoter from '../../images/co-promoter.jpg';
import co_founder from '../../images/co-promoter.jpg';
import growth from '../../images/growth.png';
import entartica from '../../images/entartica-logo.png';
import expovan from '../../images/expovan.png';
import mentorbi from '../../images/MENTOR-logo.png';
import medimine from '../../images/partnerLogo/healthstart.png';
function HowWeEngage(props) {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  const Models=[
    // {
    //   model:'build_Model',
    //   heading:'Build Model',
    //   description:'Build model, is ideal for entrepreneurs who are seeking capacity and capital. Through this module, we provide startups with access to funding, as well as resources and support to help them build and scale their operations',
    //   img:buildModel,
    //   companyLogo:entartica,
    //   tagline:" \'Leading India's water recreation. Build Model empowers startups with capital and resources for scaling, shaping a global aquatic leisure chain.\'"
    // },
    {
      model:'Mandate_Model',
      heading:'Mandate Model',
      description:'Mandate model, is ideal for entrepreneurs who are seeking clarity and capacity. Through this module, we provide startups with access to experienced consultants and advisors who can provide strategic guidance and help them build the capacity they need to scale their businesses',
      img:mandateModel,
      companyLogo:expovan,
      tagline:"\'Elevating Vanilla processing. Mandate Model empowers startups with expert guidance and capacity-building, fostering growth in the Industry.\'"
    },
    {
      model:'promoter_Model',
      heading:'Promoter Model',
      description:'Co-promoter model, is ideal for entrepreneurs who are seeking connection and capital. Through this module, we connect startups with investors and other industry players who can provide funding, networking opportunities, and other resources to help them grow their businesses\'',
      img:coPromoter,
      companyLogo:mentorbi,
      tagline:"\'Fueled by consulting prowess. Co-promoter model links startups with investors and industry players, fostering capital and connections for impactful growth.\'"
      
    },
    // {
    //   model:'Co-founder_Model',
    //   heading:'Co-founder Model',
    //   description:'Co-founder model, which is ideal for entrepreneurs who are seeking clarity and connection. Through this module, we connect entrepreneurs with experienced co-founders who can provide guidance, mentorship, and support as they build their businesses.',
    //   img:co_founder,
    //   companyLogo:medimine,
    //   tagline:"\' Spearheading clinic digitisation. Co-founder model fosters clarity, connecting visionaries with experienced mentors for healthcare evolution\'"


    // },
    {
      model:'growth_Model',
      heading:'Growth Model',
      description:'The growth model is ideal for businesses who are seeking capacity and capital. Through this model, we provide businesses with access to funding, as well as resources and support to help them build and scale their operations',
      img:growth,
      companyLogo:medimine,
      tagline:"\' Spearheading clinic digitisation. Co-founder model fosters clarity, connecting visionaries with experienced mentors for healthcare evolution\'"


    }
  ];
  return (
    <div>
       <div className='container-fluid'>
        <div className='row F-div' >
        <div className='col-md-1 '></div>

        <div className='col-md-10 hwe-mid-div '>
          <div className='row align-drop-div water-drop-show-hide-mobile m-0'>
 
          <div className='col-md-3 align-c-drop'>
            {/* <div className='py-4 water-drop'> */}
            <img src={props.wd1} alt='cc' className='' ></img> 
            {/* </div> */}
             <div className='c-div'> 
              <div className='c-round'>C</div> <div className='c-name'>Connections</div>
            </div>
            </div>

             <div className='col-md-3 align-c-drop'>
            {/* <div className='py-4 water-drop'> */}
            <img src={props.wd2} alt='cc' className='bg-color-wd-2' ></img> 
            {/* </div> */}
             <div className='c-div'> 
              <div className='c-round'>C</div> <div className='c-name'>Capability</div>
            </div>
            </div>

            <div className='col-md-3 align-c-drop'>
            {/* <div className='py-4 water-drop'> */}
            <img src={props.wd3} alt='cc' className='' ></img> 
            {/* </div> */}
             <div className='c-div'> 
              <div className='c-round'>C</div> <div className='c-name'>Clarity</div>
            </div>
            </div>

         <div className='col-md-3 align-c-drop'>
            {/* <div className='py-4 water-drop'> */}
            <img src={props.wd4} alt='cc' className='' ></img> 
            {/* </div> */}
             <div className='c-div'> 
              <div className='c-round'>C</div> <div className='c-name'>Capital</div>
            </div>
            </div>

          </div>
          
          <div className='water-drop-show-hide-desk'>
          <img src={props.waterDrop} alt='water-drop-img-desktop' className='water-drop-img-desktop' style={{width: '-webkit-fill-available'}}></img>
          <div className='d-flex c-4-div'>
          <div className='col-md-12 d-flex'>
             <div className='col-md-3 c-div'> 
              <div className='c-round'>C</div> <div className='c-name'>Connections</div>
            </div>
            <div className='col-md-3 c-div'> 
              <div className='c-round'>C</div> <div className='c-name'>Capability</div>
            </div>
            <div className='col-md-3 c-div'> 
              <div className='c-round'>C</div> <div className='c-name'>Clarity</div>
            </div>
            <div className='col-md-3 c-div'> 
              <div className='c-round'>C</div> <div className='c-name'>Capital</div>
            </div>
          </div>     
          </div>
          </div>
        </div>

        <div className='col-md-1'></div>
       </div>
       <div className='row bg-sec-div'>
       <div className='col-md-1'></div>
        <div className='col-md-10 text-start'>
           <h4 style={{fontWeight:'600'}}>How we engage </h4>
           <p>A startup accelerator empowering entrepreneurs with diverse expertise and cutting-edge technology. Overcome challenges, accelerate growth, and foster innovation through mentorship, resources, and networking. We provide mentorship across functional areas, helping startups overcome challenges in marketing, finance, operations, and product development. With a focus on fostering a vibrant community, Marg’Om offers networking opportunities and knowledge-sharing events. Join us to bring your innovative ideas to life, accelerate growth, and overcome obstacles with the help of experienced mentors and state-of-the-art technology.</p>
          
       </div>
       <div className='col-md-1'></div>
       </div>
       <div className='col-ms-12'> <img className=' hwe-bottomImg' alt='buddha-bottom' src={props.blackBuddhaImg}></img></div>
       </div>
       {
        Models.map((model)=>{
          return (

          
          <div>
          <div className={`row`}>
              <div className={`col-md-6 bg-model`}>
                  <img src={model.img} width='450px' style={{padding:' 20px'}} className='bm-img'></img>
              </div>
              <div className='col-md-6 d-flex bg-bm-2'>
                  <div className='col-md-1'></div>
              <div className="col-md-10 align-self-center">
                <div className='text-start build-model-text'>
                  <h2>{model.heading}</h2>
                  <span className='bm-desc'>{model.description}</span>  
                  {/* <div className='mt-5 d-flex align-items-center'> <div className='mx-2'><img src={model.companyLogo} width="100px"></img></div>  <div><span className='fst-italic' style={{fontSize:'15px'}}>{model.tagline}</span></div>  </div> */}

                </div>
                </div>
                <div className='col-md-1'></div>
              </div>
          </div>
          <div className='bg-bottom-city-img'>
              <img src={props.editedImg} className='bm-bottom-img'></img>
          </div> </div>)
        })
       }
       <Footer  Logo={props.Logo} />
    </div>
  )
}

export default HowWeEngage