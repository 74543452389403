import './footer.css';
import { useEffect } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
export default function Footer({Logo}){
    const navigate=useNavigate();
    useEffect(() => {
        const currentUrl = window.location.pathname + window.location.search;
        console.log('currentUrl', currentUrl);
        
        const compareLinks = (linkHref, currentUrl) => {
            const linkUrl = new URL(linkHref, window.location.origin);
            const currentUrlObj = new URL(currentUrl, window.location.origin);
            return linkUrl.pathname === currentUrlObj.pathname && linkUrl.search === currentUrlObj.search;
        };

        $('#footer-links a').each(function() {
            const link = $(this);
            const linkHref = link.attr('href');
            console.log(linkHref, currentUrl, 'link.attr');
            
            if (linkHref === 'model?type=2' || compareLinks(linkHref, currentUrl)) {
                link.find('p').css('border-bottom', '3px solid #1B6166');
            }
        });
    }, []);
    return (
        <div className="container-fluid footer-bg" >
            <div className="">
             <div className="footer-div" onClick={()=>navigate('/')}>
                <img src={Logo} alt="footer-logo" className="footer-logo"></img>
            </div>
                <div className='d-flex justify-content-center py-4' id='footer-links'>
                <a className='footer-link-div cool-link'href='/who-we-are' >
                    <p style={{fontWeight: 'bold',marginBottom:'0px'}}>About Us</p>
                </a>
                <a className='footer-link-div cool-link' href='/how-we-engage'>
                    <p style={{fontWeight: 'bold',marginBottom:'0px'}} >Our Models</p>
                </a>
                <a className='footer-link-div cool-link' href='/ecosystem'>
                    <p style={{fontWeight: 'bold',marginBottom:'0px'}} >Our Ecosystem</p>
                </a>
                <a className='footer-link-div cool-link right-border' href='/model?type=2'>
                    <p style={{fontWeight: 'bold',marginBottom:'0px'}} >Contact Us</p>
                </a>
                </div>
            
            </div>
        </div>
    )
}